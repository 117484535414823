import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import nodeRepliesPropertiesQuery from '@aurora/shared-client/components/nodes/NodeProperties/NodeRepliesProperties.query.graphql';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import type { RepliesFormat } from '@aurora/shared-generated/types/graphql-schema-types';
import { useContext } from 'react';
import type {
  NodeRepliesPropertiesQuery,
  NodeRepliesPropertiesQueryVariables,
  UserRepliesSettingsQuery,
  UserRepliesSettingsQueryVariables
} from '../../../types/graphql-types';
import userRepliesSettings from '../MessageListMenu/UserRepliesSettings.query.graphql';

/**
 * Represents the properties related to replies.
 */
interface RepliesPropertiesType {
  /**
   * Replies format, i.e., Threaded or Linear
   */
  repliesFormat: RepliesFormat;
  /**
   * Whether the nodeRepliesPropertiesQuery and UserRepliesSettingsQuery are still loading.
   */
  repliesFormatLoading: boolean;
}

/**
 * Get the UI-friendly properties related to replies.
 * User's replies settings takes precedence, then node leven, then default.
 *
 * @param nodeId Given board's node id.
 * @author Prashanth T R
 */
export default function useRepliesSettingsProperties(nodeId: string): RepliesPropertiesType {
  const { isAnonymous } = useRegistrationStatus();
  const { community } = useContext(AppContext);
  const { data: nodeRepliesPropertiesData, loading: nodeRepliesFormatLoading } =
    useQueryWithTracing<NodeRepliesPropertiesQuery, NodeRepliesPropertiesQueryVariables>(
      module,
      nodeRepliesPropertiesQuery,
      {
        variables: {
          nodeId: nodeId ?? community.id
        }
      }
    );

  const repliesFormat = nodeRepliesPropertiesData?.coreNode?.repliesProperties?.repliesFormat;

  const { data: userData, loading: userDataLoading } = useQueryWithTracing<
    UserRepliesSettingsQuery,
    UserRepliesSettingsQueryVariables
  >(module, userRepliesSettings, { skip: isAnonymous });

  const userRepliesFormat = userData?.self?.profileSettings?.repliesFormat?.localValue;

  return {
    repliesFormat: (userRepliesFormat || repliesFormat) as RepliesFormat,
    repliesFormatLoading: nodeRepliesFormatLoading || userDataLoading
  };
}
