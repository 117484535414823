import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { MessageListMenuItem } from './MessageListMenu/MessageListMenu';

/**
 * Returns either replies should be in the viw or not.
 *
 */
export function useAutoScrollToReplies(): boolean {
  const { router } = useEndUserRoutes();
  const repliesSortOrder = router.getUnwrappedQueryParam(
    EndUserQueryParams.SORT_MENU_QUERY_PARAMETER
  );
  const messageReplySortOrder: string[] = Object.values(MessageListMenuItem);
  return (
    router.getUnwrappedQueryParam(EndUserQueryParams.AUTO_SCROLL) === 'true' &&
    messageReplySortOrder.includes(repliesSortOrder) === true
  );
}
